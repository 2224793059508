.preview-picture-panel {
  .pwsb-panel-header {
    .tool-bar {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tool-bar-left {
        flex: 2;
        display: flex;
        min-width: 300px;
        gap: 5px;
      }
      .tool-bar-right {
        margin-right: 80px;
        display: flex;
        gap: 10px;
        align-items: center;

        .navigation-controls {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        .separator {
          height: 12px;
        }
      }
    }
    .close-button {
      top: 23px !important;
    }
  }

  .fade-in-effect {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}
