.due-date-control {
  .ms-Dropdown:active:not(.is-disabled) > .ms-Dropdown-title {
    border: 2px solid var(--grey-190);
  }
  .due-date-picker {
    &:hover {
      .ms-TextField-fieldGroup {
        border: 2px solid var(--grey-190);
      }
    }
  }

  #dpFromDate {
    .ms-TextField-suffix {
      position: absolute;
      right: 0;
      padding: 7px 0px 5px 0px;
    }
  }
  #dpToDate {
    .ms-TextField-suffix {
      position: absolute;
      right: 0;
      padding: 7px 0px 5px 0px;
    }
  }
}
