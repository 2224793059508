@font-face {
  font-family: 'onix-icon';
  src:
    url('../fonts/icon-fonts/onix-icon.ttf?hudp8u') format('truetype'),
    url('../fonts/icon-fonts/onix-icon.woff?hudp8u') format('woff'),
    url('../fonts/icon-fonts/onix-icon.svg?hudp8u#onix-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="oi-"], [class*=" oi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'onix-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oi-others-tag:before {
  content: "\e9b9";
}
.oi-tagged-equipment:before {
  content: "\e9ba";
}
.oi-cart:before {
  content: "\e9b8";
}
.oi-rental-status:before {
  content: "\e9b4";
}
.oi-rented-equipment:before {
  content: "\e9b5";
}
.oi-rented-internal-equipment:before {
  content: "\e9b6";
}
.oi-rented-out-equipment:before {
  content: "\e9b7";
}
.oi-camera-filled:before {
  content: "\e9b3";
}
.oi-camera:before {
  content: "\e9b2";
}
.oi-set-checkpoint-statuses:before {
  content: "\e9b1";
}
.oi-text-tool-add-text:before {
  content: "\e9ae";
}
.oi-text-tool-size:before {
  content: "\e9af";
}
.oi-text-tool:before {
  content: "\e9b0";
}
.oi-status-circle-m:before {
  content: "\e9ac";
}
.oi-status-square-m:before {
  content: "\e9ad";
}
.oi-location-solid:before {
  content: "\e9ab";
}
.oi-general-object:before {
  content: "\e9a6";
}
.oi-equipment-relation:before {
  content: "\e9a8";
}
.oi-equipment-type:before {
  content: "\e9a9";
}
.oi-unique-equipment:before {
  content: "\e9aa";
}
.oi-document-type-excel:before {
  content: "\e9a2";
}
.oi-document-type-image:before {
  content: "\e9a3";
}
.oi-document-type-pdf:before {
  content: "\e9a4";
}
.oi-document-type-word:before {
  content: "\e9a5";
}
.oi-sub-child:before {
  content: "\e9a1";
}
.oi-certificate-included:before {
  content: "\e998";
}
.oi-consumable-equipment:before {
  content: "\e999";
}
.oi-declaration-of-conformity-included:before {
  content: "\e99a";
}
.oi-instruction-included:before {
  content: "\e99b";
}
.oi-is-rented-equipment:before {
  content: "\e99c";
}
.oi-open-job-exists:before {
  content: "\e99d";
}
.oi-stock-equipment:before {
  content: "\e99e";
}
.oi-image-check:before {
  content: "\e996";
}
.oi-sort-za:before {
  content: "\e995";
}
.oi-sort-az:before {
  content: "\e994";
}
.oi-nfc:before {
  content: "\e997";
}
.oi-lightpin:before {
  content: "\e990";
}
.oi-solidpin:before {
  content: "\e991";
}
.oi-pushpin:before {
  content: "\e992";
}
.oi-pin:before {
  content: "\e993";
}
.oi-infor:before {
  content: "\e98f";
}
.oi-logo-illus-onix:before {
  content: "\e902";
}
.oi-logo-onix:before {
  content: "\e903";
}
.oi-logo-type-work:before {
  content: "\e904";
}
.oi-logo-type-worker:before {
  content: "\e905";
}
.oi-logo-type-onix:before {
  content: "\e906";
}
.oi-a:before {
  content: "\61";
}
.oi-b:before {
  content: "\62";
}
.oi-c:before {
  content: "\63";
}
.oi-d:before {
  content: "\64";
}
.oi-e:before {
  content: "\65";
}
.oi-f:before {
  content: "\66";
}
.oi-g:before {
  content: "\67";
}
.oi-h:before {
  content: "\68";
}
.oi-i:before {
  content: "\69";
}
.oi-j:before {
  content: "\6a";
}
.oi-k:before {
  content: "\6b";
}
.oi-l:before {
  content: "\6c";
}
.oi-m:before {
  content: "\6d";
}
.oi-n:before {
  content: "\6e";
}
.oi-o:before {
  content: "\6f";
}
.oi-p:before {
  content: "\70";
}
.oi-q:before {
  content: "\71";
}
.oi-r:before {
  content: "\72";
}
.oi-s:before {
  content: "\73";
}
.oi-t:before {
  content: "\74";
}
.oi-u:before {
  content: "\75";
}
.oi-v:before {
  content: "\76";
}
.oi-w:before {
  content: "\77";
}
.oi-x:before {
  content: "\78";
}
.oi-y:before {
  content: "\79";
}
.oi-z:before {
  content: "\7a";
}
.oi-minus:before {
  content: "\e98e";
}
.oi-close-circle:before {
  content: "\e98d";
}
.oi-parent-child:before {
  content: "\e9a7";
}
.oi-redo:before {
  content: "\e98b";
}
.oi-undo:before {
  content: "\e98c";
}
.oi-redo-circle:before {
  content: "\e95e";
}
.oi-undo-circle:before {
  content: "\e95d";
}
.oi-department:before {
  content: "\e919";
}
.oi-building:before {
  content: "\e91a";
}
.oi-location-alt:before {
  content: "\e95c";
}
.oi-users:before {
  content: "\e918";
}
.oi-arrow-down:before {
  content: "\e914";
}
.oi-arrow-left:before {
  content: "\e915";
}
.oi-arrow-right:before {
  content: "\e916";
}
.oi-arrow-up:before {
  content: "\e917";
}
.oi-compress:before {
  content: "\e912";
}
.oi-expand:before {
  content: "\e913";
}
.oi-job-inspection-single:before {
  content: "\e90a";
}
.oi-lock:before {
  content: "\e90b";
}
.oi-job-archive:before {
  content: "\e90c";
}
.oi-job-inspection-mixed:before {
  content: "\e90d";
}
.oi-job-inspection-plus:before {
  content: "\e90e";
}
.oi-job-inspection-quick:before {
  content: "\e90f";
}
.oi-job-maint:before {
  content: "\e910";
}
.oi-job-report:before {
  content: "\e911";
}
.oi-search-contain:before {
  content: "\e907";
}
.oi-search-equal:before {
  content: "\e908";
}
.oi-search-start-with:before {
  content: "\e909";
}
.oi-download:before {
  content: "\e900";
}
.oi-mobile:before {
  content: "\e901";
}
.oi-angle-double-down:before {
  content: "\e91b";
}
.oi-angle-double-left:before {
  content: "\e91c";
}
.oi-angle-double-right:before {
  content: "\e91d";
}
.oi-angle-double-up:before {
  content: "\e91e";
}
.oi-arrow-circle-down:before {
  content: "\e91f";
}
.oi-arrow-circle-left:before {
  content: "\e920";
}
.oi-arrow-circle-right:before {
  content: "\e921";
}
.oi-arrow-circle-up:before {
  content: "\e922";
}
.oi-bars:before {
  content: "\e923";
}
.oi-calendar:before {
  content: "\e924";
}
.oi-calendar-alt:before {
  content: "\e925";
}
.oi-camera1:before {
  content: "\e926";
}
.oi-camera-plus:before {
  content: "\e927";
}
.oi-caret-down:before {
  content: "\e928";
}
.oi-caret-left:before {
  content: "\e929";
}
.oi-caret-right:before {
  content: "\e92a";
}
.oi-caret-up:before {
  content: "\e92b";
}
.oi-chart-pie:before {
  content: "\e92c";
}
.oi-check:before {
  content: "\e92d";
}
.oi-check-circle:before {
  content: "\e92e";
}
.oi-check-circle-o:before {
  content: "\e92f";
}
.oi-check-square:before {
  content: "\e930";
}
.oi-check-square-o:before {
  content: "\e931";
}
.oi-chevron-down:before {
  content: "\e932";
}
.oi-chevron-left:before {
  content: "\e933";
}
.oi-chevron-right:before {
  content: "\e934";
}
.oi-chevron-up:before {
  content: "\e935";
}
.oi-circle:before {
  content: "\e936";
}
.oi-circle-dot:before {
  content: "\e937";
}
.oi-close:before {
  content: "\e938";
}
.oi-cog:before {
  content: "\e939";
}
.oi-comment:before {
  content: "\e93a";
}
.oi-comment-ellipsis:before {
  content: "\e93b";
}
.oi-copy:before {
  content: "\e93c";
}
.oi-dashboard:before {
  content: "\e93d";
}
.oi-ellipsis-h:before {
  content: "\e93e";
}
.oi-ellipsis-h-circle:before {
  content: "\e93f";
}
.oi-ellipsis-v:before {
  content: "\e940";
}
.oi-equipment:before {
  content: "\e941";
}
.oi-exclamation-circle:before {
  content: "\e942";
}
.oi-exclamation-circle-o:before {
  content: "\e943";
}
.oi-ext-procedure:before {
  content: "\e944";
}
.oi-ext-procedure-filled:before {
  content: "\e945";
}
.oi-file:before {
  content: "\e946";
}
.oi-file-chart:before {
  content: "\e947";
}
.oi-file-image:before {
  content: "\e948";
}
.oi-file-pdf:before {
  content: "\e949";
}
.oi-file-plus:before {
  content: "\e94a";
}
.oi-filter:before {
  content: "\e94b";
}
.oi-heart:before {
  content: "\e94c";
}
.oi-heart-o:before {
  content: "\e94d";
}
.oi-home:before {
  content: "\e94e";
}
.oi-image:before {
  content: "\e94f";
}
.oi-image-plus:before {
  content: "\e950";
}
.oi-issue:before {
  content: "\e951";
}
.oi-issue-drop:before {
  content: "\e952";
}
.oi-issue-plus:before {
  content: "\e953";
}
.oi-issue-solved:before {
  content: "\e954";
}
.oi-issue-todo:before {
  content: "\e955";
}
.oi-jc-legal:before {
  content: "\e956";
}
.oi-jc-maint-operator:before {
  content: "\e957";
}
.oi-jc-maint-preventive:before {
  content: "\e958";
}
.oi-job:before {
  content: "\e959";
}
.oi-job-plus:before {
  content: "\e95a";
}
.oi-location:before {
  content: "\e95b";
}
.oi-minus-circle:before {
  content: "\e95f";
}
.oi-pen:before {
  content: "\e960";
}
.oi-pen-circle:before {
  content: "\e961";
}
.oi-plus:before {
  content: "\e962";
}
.oi-plus-circle:before {
  content: "\e963";
}
.oi-qr-code:before {
  content: "\e964";
}
.oi-rescue:before {
  content: "\e965";
}
.oi-rfid:before {
  content: "\e966";
}
.oi-save:before {
  content: "\e967";
}
.oi-search:before {
  content: "\e968";
}
.oi-share:before {
  content: "\e969";
}
.oi-share-square:before {
  content: "\e96a";
}
.oi-signin:before {
  content: "\e96b";
}
.oi-signout:before {
  content: "\e96c";
}
.oi-sliders:before {
  content: "\e96d";
}
.oi-sort:before {
  content: "\e96e";
}
.oi-sort-down:before {
  content: "\e96f";
}
.oi-sort-up:before {
  content: "\e970";
}
.oi-square:before {
  content: "\e971";
}
.oi-star:before {
  content: "\e972";
}
.oi-star-o:before {
  content: "\e973";
}
.oi-status-circle-c:before {
  content: "\e974";
}
.oi-status-circle-check:before {
  content: "\e975";
}
.oi-status-circle-mo:before {
  content: "\e976";
}
.oi-status-circle-na:before {
  content: "\e977";
}
.oi-status-circle-nc:before {
  content: "\e978";
}
.oi-status-circle-ok:before {
  content: "\e979";
}
.oi-status-circle-question:before {
  content: "\e97a";
}
.oi-status-circle-rc:before {
  content: "\e97b";
}
.oi-status-circle-u:before {
  content: "\e97c";
}
.oi-status-square-c:before {
  content: "\e97d";
}
.oi-status-square-check:before {
  content: "\e97e";
}
.oi-status-square-mo:before {
  content: "\e97f";
}
.oi-status-square-na:before {
  content: "\e980";
}
.oi-status-square-nc:before {
  content: "\e981";
}
.oi-status-square-ok:before {
  content: "\e982";
}
.oi-status-square-question:before {
  content: "\e983";
}
.oi-status-square-rc:before {
  content: "\e984";
}
.oi-status-square-u:before {
  content: "\e985";
}
.oi-sync-circle .path1:before {
  content: "\e986";
  color: rgb(35, 31, 32);
}
.oi-sync-circle .path2:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.oi-sync-circle .path3:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.oi-toggle-off:before {
  content: "\e987";
}
.oi-toggle-on:before {
  content: "\e988";
}
.oi-user:before {
  content: "\e989";
}
.oi-user-plus:before {
  content: "\e98a";
}

.oi-custom-due-icon.legal:before {
  content: "\e956";
}

.oi-custom-due-icon.operator:before {
  content: "\e957";
}

.oi-custom-due-icon.preventive:before {
  content: "\e958";
}

.oi-custom-due-icon.bad {
  color: #C40000;
}

.oi-custom-due-icon.good {
  color: #00820F;
}

.oi-custom-due-icon.alert {
  color: #D2AE00;
}

.oi-custom-due-icon.header {
  color: lightgrey;
}

.oi-issue.header {
  color: lightgrey;
}