.spin-loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .rotate-icon {
    animation: spin 2s linear infinite;
    width: 30px;
    height: 30px;
    color: var(--black-color);
  }

  .loadingText {
    margin-left: 5px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }}