.ms-DetailsList.table-list, .ms-ScrollablePane:has(.table-list) {
  overflow: unset;
  .ms-DetailsHeader {
    padding: 0px;
    border-top: solid 1px $grey30;
    height: 30px;
    line-height: 30px;
    .ms-DetailsHeader-cell,.ms-DetailsHeader-cellIsCheck{
      border-left: solid 1px $grey30;
      border-collapse: collapse;
      height: 30px;
      line-height: 30px;

      .ms-DetailsHeader-cellTitle[aria-haspopup="menu"]:hover{
        cursor: pointer;
      }

      .ms-DetailsHeader-cellTitle {
        padding-right: 0px;
        
        .icon.icon16 {
          padding-top: 1px;
          margin-top: 5px;
        }
      }
    }
 
    .ms-DetailsHeader-cellIsCheck{
      border-left: none;
      height: 30px;
      line-height: 30px;
    }
  }

  .ms-DetailsRow {
    border-bottom: solid 1px $grey30;
    color: $grey160;
    min-width: 100% !important;

    &:hover {
    }
    &.is-selected {
      .link-column, .cell-link {
        font-weight: bold;
      }
    }

    .ms-DetailsRow-cellCheck {
      .ms-DetailsRow-check{
        height: 100%;
      }

      .ms-Check {
        &.is-checked {
          &::before {
            background: var(--primary-color);
          }
        }
      }
    }

    .ms-DetailsRow-cell:not(.ms-DetailsRow-cellCheck) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 13px;
      & *:not(.ms-Icon, i) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .content, .ms-Link{
        max-width: 100%;
      }
    }

    .ms-DetailsRow-fields:has(.row-opacity-blur) {
      opacity: 0.5 !important;
    }
  }

  .ms-Panel-footer{
    border-top: 1px solid $grey30,
  }

  .icon.icon16 {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ms-Check {
    &.is-checked {
      &::before {
        background: var(--primary-color);
      }
      .ms-Check-check {
        color: var(--text-color);
      }
    }
  }
}

//for single selection
.ms-DetailsList.table-single-selection .ms-GroupHeader{
  .ms-GroupSpacer{
    display: none !important;
  }
  .ms-GroupHeader-dropIcon{
    display: none !important;
  }
  .ms-GroupHeader-title{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
//for icon column
.width40 { width: 40px !important;  }
.width60 { width: 60px !important; }
.width80 { width: 80px !important; }