@use "../../../assets/index.scss" as *;
$heightHeader: 40px;

.onix-split-table-container {
    max-height: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .width40 {
        width: 40px !important;
    }

    .width60 {
        width: 60px !important;
    }

    .width80 {
        width: 80px !important;
    }

    .onix-split-table-wrapper {
        flex: 1;
        position: relative;

        .onix-table {
            & .clickable-cell {
                text-decoration: underline;
                cursor: pointer;
                color: $textLink;
                width: fit-content;
                max-width: 100%;
            }
        }

        & .ms-DetailsHeader {
            height: $heightHeader;
            padding: 0px;
            border-bottom: solid 1px #EDEBE9;
            width: 100%;
            @include row-flex;


            &:first-child {
                border-top: none;
            }

            >.is-actionable {
                flex: 1;
            }

            

            .ms-DetailsHeader-cell {
                padding: 0px;
                height: $heightHeader;
                position: relative;

                &.is-empty {
                    flex: 1;
                }
                &[aria-expanded] {
                    width: 36px !important;
                }

                .ms-DetailsHeader-cellTitle {
                    width: 100%;
                    height: $heightHeader;
                    padding: 0;
                    cursor: pointer;

                    .ms-DetailsHeader-cellName {
                        width: 100%;
                    }
                }
            }

            .ms-DetailsHeader-cellIsCheck {
                border-left: none;

                .ms-DetailsRow-check {
                    width: 30px;
                }

                .ms-Check {
                    &.is-checked {
                        &::before {
                            background: rgb(141, 57, 142);
                        }
                    }
                }
            }
        }

        .onix-split-table {

            & .ms-DetailsList-headerWrapper {
                z-index: 10;
                height: $heightHeader + 2px;
                position: sticky;
                position: -webkit-sticky;
            }

            .ms-DetailsList-contentWrapper {
                z-index: 0;

                .ms-GroupedList {
                    width: 100%;
                }

                .ms-GroupedList-group {
                    .ms-GroupHeader {
                        .ms-GroupHeader-check {
                            width: 30px;

                            .ms-Check {
                                &.is-checked {
                                    &::before {
                                        background: rgb(141, 57, 142);
                                    }
                                }
                            }
                        }

                        .ms-GroupHeader-title {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .ms-DetailsRow {
                    border-bottom: solid 1px #EDEBE9;
                    color: #323130;
                    padding: 0px;

                    &:hover {
                        /*background-color: #f9f2f9;*/
                    }

                    &.is-selected {
                        /*background-color: #e8d8e8;*/
                    }

                    >.ms-DetailsRow-fields {
                        width: 100%;

                        >* {
                            width: 100%;
                            flex: 1;
                        }
                    }

                    .ms-DetailsRow-cellCheck {
                        .ms-DetailsRow-check {
                            width: 30px;
                        }

                        .ms-Check {
                            &.is-checked {
                                &::before {
                                    /*background: rgb(141, 57, 142);*/
                                }
                            }
                        }
                    }

                    .ms-DetailsRow-cell {
                        padding: 0px;
                    }

                    .icon {
                        margin-left: 0;
                    }
                }
            }

            & .onix-split-row {
                @include column-flex;
                gap: 5px;
            }
        }

        .empty-table {
            @include row-flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    .total-items {
        @include row-flex;
        gap: 5px;
        box-sizing: border-box;
        font-weight: normal;
        padding-right: 12px;
        cursor: default;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        >span {
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .onix-split-header {
        @include row-flex;
        width: 100%;

        &-content {
            flex: 1;
            cursor: default;
        }
    }
}