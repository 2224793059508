@font-face {
  font-family: 'Segoe UI Regular';
  font-style: normal;
  font-weight: normal;
  src: url('Segoe UI.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'onix-icon';
  src: url('icon-fonts/onix-icon.ttf?hudp8u') format('truetype'), url('icon-fonts/onix-icon.woff?hudp8u') format('woff'),
    url('icon-fonts/onix-icon.svg?hudp8u#onix-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/* We could add files to support other font-style in the future but a heavy redundancy:*/
/*@font-face {
    font-family: 'Segoe UI Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Italic'), url('Segoe UI Italic.woff') format('woff');
}
@font-face {
    font-family: 'Segoe UI Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold'), url('Segoe UI Bold.woff') format('woff');
}
@font-face {
    font-family: 'Segoe UI Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI Bold Italic'), url('Segoe UI Bold Italic.woff') format('woff');
}*/
