.picture-management-panel {
  .pwsb-panel-header {
    .description {
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .pwsb-panel-body {
    overflow: hidden;
  }
  .picture-management-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
