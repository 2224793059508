@use '../../../../assets/scss/index.scss' as *;

.related-rows {
  @include column-flex;
  gap: 1px;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
  & > * {
    border-bottom: 1px solid $grey30;
  }
  & > *:last-child {
    border-bottom: 0px;
  }
  .related-button {
    @include row-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 5px;
    height: 50px;
    font-weight: 500;
    > .relation-equipment-content {
      @include row-flex;
      gap: 8px;
    }
    &:hover {
      color: $primaryColor;
    }
  }
}