@use '../../../../assets/scss/index.scss' as *;

.sections .collapse {
  visibility: visible !important;
}

.equipment-content {
  @include column-flex;
  gap: 15px;

  .content-sections {
    @include column-flex;
    
    .expand-collapse-buttons {
      @include row-flex;
      justify-content: flex-end;
      
    }
    .expand-collapse-buttons button{
      &:hover {
        color: $primaryColor;
        & span i{
          color: $primaryColor;
        }
      }
    }
    .sections {
      @include column-flex;
      gap: 15px;
      & .section {
          @include column-flex;
          gap: 5px;
          font-size: 12px;
        }
      
        .title-section {
          font-weight: 600;
          size: 14px;
          color: $grey160;
        }
    }
  }

  .collapse-header {
    height: 32px;
  }

  .equipment-detail-collapse > .collapse-body {
    padding: 8px 8px 0px 8px;
  }

  .detail-header {
    @include row-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .detail-header-button {
      @include row-flex;
      & > * {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  
  i[data-icon-name="ChevronUp"],
  i[data-icon-name="ChevronDown"] {
    color: #323130;
  }
}
