@import url('../css/icon-font.css');
@import url('../css/onix-icon.css');

:root {
  --grey-10: #faf9f8;
  --grey-30: #edebe9;
  --grey-50: #d2d0ce;
  --grey-90: #a19f9d;
  --grey-110: #8a8886;
  --grey-130: #605e5c;
  --grey-190: #201f1e;
  --grey-160: #323130;
  --black-color: rgb(50, 49, 48);
  --disabled-text-color: rgb(161, 159, 157);
}

.ms-layer {
  display: none;
}

.common-dialog .ms-Dialog-main {
  min-height: unset;
  max-width: 50% !important;
  max-height: 700px !important;
}

.ms-TextField-suffix {
  background: unset;
  padding: 0px;
}

.ms-DetailsList .ms-DetailsRow .ms-DetailsRow-cellCheck .ms-Check.is-checked::before,
.ms-DetailsList .ms-DetailsHeader .ms-DetailsHeader-cell.ms-DetailsHeader-cellIsCheck .ms-Check.is-checked::before {
  background-color: var(--primary-color);
}

.ms-DetailsList .ms-DetailsRow .ms-DetailsRow-cellCheck .ms-Check.is-checked .ms-Check-check {
  color: var(--text-color);
}

button.ms-Button.ms-Button--primary,
button.ms-Button.ms-Button--primary:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
}

button.ms-Button.is-disabled {
  @apply opacity-70 cursor-default;
}

.ms-Modal .ms-Overlay.ms-Overlay--dark {
  opacity: 0.5;
}

.ms-CommandBar .ms-Button.ms-Button--commandBar .ms-Button-icon,
.ms-Button.ms-Button--commandBar .ms-Button-icon,
.ms-Button.ms-Button--command .ms-Button-icon {
  color: var(--black-color);
}

.ms-TextField-wrapper > .ms-TextField-fieldGroup {
  border-radius: 2px;
  width: 100%;
}

.ms-DetailsHeader .ms-DetailsHeader-cellSizer {
  cursor: ew-resize;
}

.datePicker .ms-TextField:not(.is-disabled) .ms-TextField-fieldGroup {
  border: 1px solid var(--grey-110);
}

.ms-DetailsList .ms-FocusZone.ms-DetailsHeader {
  padding-top: 0px;
}
.ms-Toggle.is-checked .ms-Toggle-innerContainer .ms-Toggle-background,
.ms-Toggle.is-checked .ms-Toggle-innerContainer .ms-Toggle-background:hover {
  @apply bg-primarybg text-primarytext shadow-depth4;
}

.ms-Toggle.is-checked .ms-Toggle-innerContainer .ms-Toggle-background .ms-Toggle-thumb {
  @apply bg-primarytext;
}

.link-download a {
  color: -webkit-link;
  cursor: pointer;
}

.load-more-button-group {
  color: purple;
  margin: 10px 0px 12px 90px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: purple !important;
  }
}
