@use '../../../../assets/scss/index.scss' as *;

.equipment-header {
  height: 32px;
  background-color: $grey30;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px 8px;
  @include row-flex;
  justify-content: space-between;
  column-gap: 10px;

  .loading {
    @include column-flex;
    gap: 7px;
    width: 100%;
    height: 100%;
  }
  .left {
    @include row-flex;
    align-items: center;
    gap: 5px;
    flex: 1 1 auto;
    overflow: hidden;
  }
  .right {
    @include row-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    flex: 0 0 auto;
    > * {
      @include row-flex;
      gap: 5px;
    }
  }

  .oi-tagged-equipment {
    font-size: 15px;
  }
}

.equipment-detail-container .header-info {
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
}
.equipment-detail-container .header-info.detail-table-mode {
  top: 60px;
  z-index: 99;
}