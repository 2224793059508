@use "../../../assets/scss/index.scss" as *;

.collapse {
  transition: 0.25s;
  overflow: hidden;

  &-header {
    @include row-flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 8px;
    cursor: pointer;


    font-weight: 600;
    size: 14px;
    color: $grey160;

    background-color: $grey30;

    &:hover {
      background-color: $grey30;
    }
  }

  &-body {
    box-sizing: border-box;
    padding-top: 15px;
    position: relative;
  }
}