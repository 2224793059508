.common-date {
  .ms-Dropdown:active > .ms-Dropdown-title {
    border: 2px solid var(--grey-190);
  }
  .common-date-picker {
    &:hover {
      .ms-TextField-fieldGroup {
        border: 2px solid var(--grey-190);
      }
    }
  }
}

#dpCommonFromDate {
  .ms-TextField-suffix {
    position: absolute;
    right: 0;
    padding: 7px 0px 5px 0px;
    &:hover {
      font-weight: 800 !important;
    }
  }
}
#dpCommonToDate {
  .ms-TextField-suffix {
    position: absolute;
    right: 0;
    padding: 7px 0px 5px 0px;
    &:hover {
      font-weight: 800 !important;
    }
  }
}
