.picture-toolbar {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  height: 46px;

  .tool-bar-left {
    flex: 1 1 auto;
    display: flex;
    min-width: 300px;
    gap: 10px;
  }

  .tool-bar-right {
    display: flex;
    flex-direction: row;
  }
}
