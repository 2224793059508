@use '../../../assets/scss/index.scss' as *;

.multiple-selection-panel {
  .ms-Panel-scrollableContent {
    display: flex;
    flex-direction: column;
    height: 100%;

    > * {
      box-sizing: border-box;

      padding: {
        left: 24px;
        right: 14px;
      }
    }

    .multiple-selection-header-panel {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      box-sizing: border-box;
      padding-top: 18px;
      position: sticky;
      top: 0;
      right: 24px;
      background: $white;
      z-index: 99;

      > * {
        width: 100%;
      }

      .close-button {
        position: absolute;
        right: 0;
        top: 18px;
        right: 14px;
        color: $grey130;
        width: 32px;
        height: 32px;
        max-height: 32px;
      }

      .title {
        color: $black;
        font-size: 20px;
        font-weight: 600;
      }

      .ms-SearchBox {
        .ms-SearchBox-iconContainer {
          color: $grey190;
        }
      }
    }

    .multiple-selection-body-panel {
      flex-grow: 1;
      height: 100%;
      max-height: 100%;
      overflow: hidden auto;
      box-sizing: border-box;
      padding-top: 3px;
      padding-bottom: 3px;

      .no-data-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      & .multiple-selection-checkbox {
        width: 100%;
        min-height: 33px;

        .ms-Checkbox-label {
          width: 100%;
        }
        .ms-Checkbox-text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .content {
          margin-left: 5px;
          width: calc(100% - 35px);
          padding-top: 6px;
          padding-bottom: 6px;

          .line {
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .sub {
            color: gray;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &.is-disabled {
          .ms-Checkbox-label {
            color: $grey90;
          }
        }
      }

      .hidden-checkbox {
        pointer-events: none;
      }

      .hidden-checkbox div.ms-Checkbox-checkbox {
        display: none;
      }

      .hidden-checkbox .ms-Checkbox-text {
        margin: 0;
      }

      .no-data-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      input[disabled] {
        opacity: 0;
      }
    }

    .multiple-selection-footer-panel {
      @include row-flex;
      gap: 10px;

      padding: {
        top: 15px;
        bottom: 15px;
      }
    }
  }

  .ms-Panel-commands {
    background-color: $white;
    height: 0px !important;
    padding: 0 !important;
  }

  & .additional-data-content {
    @include column-flex;
    height: 100%;
    width: 100%;
    white-space: nowrap;
    margin: 0px 4px;
    align-items: flex-start;
    justify-content: center;

    > * {
      max-width: 100%;
      width: fit-content;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .description {
      color: $grey90;
    }
  }

  .ms-List-cell {
    border-bottom: 1px solid #edebe9;
  }
}

.multiple-selection-text-field {
  input {
    padding-right: 28px;
    height: auto;
    border: unset;
    font-family: unset;
    font-size: unset;
  }

  & .icon-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 7px;
    height: 100%;

    color: rgb(96, 94, 92);

    &:focus {
      outline: none;
      border: none !important;
    }

    &-disabled {
      color: rgb(161, 159, 157);
    }

    i {
      cursor: pointer;

      &:hover {
        font-weight: 800;
      }
    }
  }

  .multiple-selection-input {
    color: #323130;
  }
}

.top-buttons-select-clear-all {
  display: flex;
  justify-content: start;
  box-sizing: border-box;
  margin-left: -5px;
}
