@use '../../../../assets/scss/index.scss' as *;

.new-ownership-field {
  @include column-flex;
  //gap: 6px;

  .sub-multiple-selection {
    display: flex;
    flex-direction: column;
    //gap: 6px;
    margin-left: 20px;
  }
}

.sub-ownership-field-panel {
  & .line {
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .sub {
    color: gray;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
  
.main-ownership-field-panel {
  & .company-instruction {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding-bottom: 12px;
  }
}