$primaryColor: #8d398e;
$secondary: #605E5C;
$lightPurple: #f9f2f9;
$textColor: #fafafa;
$white: #ffffff;
$black: #000000;
$red: #a4262c;
$grey10: #faf9f8;
$grey30: #edebe9;
$grey50: #D2D0CE;
$grey90: #a19f9d;
$grey110: #8a8886;
$grey130: #605e5c;
$grey190: #201f1e;
$grey160: #323130;

$onixgood: #00820F;
$onixoverdue: #C40000;

$textLink: rgb(12, 100, 192);

$commandBarTextColor: #605E5C;