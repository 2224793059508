@use '../../../assets/scss/index.scss' as *;

.section-row {
  width: 100%;
  color: $grey130;
  @include row-flex;
  min-height: 24px;

  > .caption {
    width: 160px;
    vertical-align: top;
    font-weight: 500;
    @include row-flex;
  }

  > .field {
    color: $grey160;
    position: relative;
    border-bottom: 1px solid $grey30;
    vertical-align: top;
    word-break: break-word;
    width: 100%;
    white-space: break-spaces;

    > .company-name {
      color: $grey130;
    }
  }
}
