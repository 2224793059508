.picture-tiles-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .list-tiles-container {
    .list {
      height: calc(100vh - 165px);
    }

    .ReactGridGallery_tile-bottom-bar-content {
      z-index: 10;
      bottom: 0px;
      position: absolute;
      width: 100%;
      height: 80px;
      .picture-info {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-left: 5px;
        justify-content: flex-end;

        .text {
          color: white;
          font-size: 12px;
        }

        .picture-description {
          color: white;
          width: 156px;
          font-size: 14px;
        }

        .created-date {
          margin-bottom: 7px;
        }

        .icon {
          position: absolute;
          bottom: 0;
          right: 0;
          background: #107c10;
          padding: 2px 5px;
          border-radius: 2px 0px 2px 0px;
          margin: 0;
          i {
            color: white;
          }
        }
      }
    }

    .ReactGridGallery_tile-viewport {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 2px;
      }
    }
  }
}
