@use './index' as *;
@import './Table-list/table-list.scss';

.text-three-dots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-tiles-container {
  .list {
    height: 100vh;

    .tile-item-container {
      display: flex;
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      border-radius: 2px;
      margin: 0px 10px 10px 0px;
      background-color: white;
      box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
      justify-content: center;
      align-items: center;

      .tile-item {
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        position: relative;
        border-radius: 2px;

        .tile-icon-bar {
          position: absolute;
          z-index: 4;
          top: 5px;
          right: 0px;

          .ms-Checkbox {
            float: right;

            .ms-Checkbox-checkbox {
              transition-duration: 0ms;
            }

            &.is-checked.is-enabled {
              .ms-Checkbox-checkbox {
                border-radius: 10px !important;
                border: 2px solid rgb(255, 255, 255);
                background-color: var(--primary-color);
                .ms-Checkbox-checkmark {
                  color: var(--text-color);
                }
              }
            }
            &.is-enabled {
              .ms-Checkbox-checkbox {
                border-radius: 10px !important;
                background-color: white;
              }
            }
          }
        }
        .tile-overlay {
          z-index: 0;
          border-radius: 2px;
        }
        .tile-gradient-overlay {
          bottom: 0px;
          position: absolute;
          width: 100%;
          height: 50%;
          background: linear-gradient(to top, #201f1e, transparent);
          z-index: 2;
          border-radius: 2px;
        }
      }
    }
  }
}

.tool-bar {
  @include row-flex;

  & > * {
    @include row-flex;
  }

  &-left {
    flex: 1 1 auto;
    display: flex;
    min-width: 300px;
    gap: 5px;

    .command-bar-wrapper {
      min-width: 80px;
      flex: 1;
    }

    & .add-new-btn {
      @include row-flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 5px;

    &.d-none {
      display: none;
    }

    // .ms-Button {
    //   margin-right: 5px;
    // }

    .ms-Button.ms-Button--commandBar {
      height: 32px;
    }

    .ms-TextField {
      padding: 0px;
      margin-right: 5px;
    }

    .callout {
      top: 101px;
      right: 485px;
    }
  }

  .ms-CommandBar {
    padding: 0px;
  }
}

.eq2-download-menu {
  .section-header .ms-ContextualMenu-linkContent {
    i {
      display: none;
    }
  }
}

.ms-ContextualMenu-linkContent,
.ms-ContextualMenu-linkContent .ms-ContextualMenu-icon,
.ms-ContextualMenu-link:hover .ms-ContextualMenu-linkContent .ms-ContextualMenu-icon {
  color: var(--black-color);
}

.ms-TextField.is-disabled.oex-input > .ms-TextField-wrapper {
  border: none;
  .ms-TextField-fieldGroup {
    .ms-TextField-field {
      color: var(--disabled-text-color);
    }
  }
}

.ms-Checkbox {
  .ms-Checkbox-checkbox {
    transition-duration: 0ms;
  }

  &.is-checked.is-enabled {
    .ms-Checkbox-checkbox {
      border: 1px solid var(--black-color);
      background-color: var(--primary-color);
      .ms-Checkbox-checkmark {
        color: var(--text-color);
      }
    }
  }
  &.is-enabled {
    .ms-Checkbox-checkbox {
      background-color: white;
    }
  }
}

.ms-Button.ms-Button--icon {
  color: rgb(96, 94, 92);
  &hover {
    color: rgb(96, 94, 92);
  }
}

.due-status-1 {
  color: #00820f;
}

.due-status-2 {
  color: #d2ae00;
}

.due-status-3 {
  color: #c40000;
}

.due-status-4 {
  visibility: hidden;
}

.status-icon {
  &.status-1 {
    color: #b22222;
  }

  &.status-2 {
    color: #d2ae00;
  }

  &.status-3 {
    color: #008000;
  }

  &.status-4 {
    color: #008000;
  }

  &.status-5 {
    color: #008000;
  }

  &.status-6 {
    color: #d3d3d3;
  }

  &.status-7 {
    color: #0054a6;
  }

  &.status-8 {
    color: #664397;
  }

  &.status-9 {
    color: #00820f;
  }

  &.status-0 {
    color: #d3d3d3;
  }

  &.status-undefined {
    color: #868686;
  }
}

.status-bg {
  &.status-1 {
    background-color: #b22222;
  }

  &.status-2 {
    background-color: #d2ae00;
  }

  &.status-3 {
    background-color: #008000;
  }

  &.status-4 {
    background-color: #008000;
  }

  &.status-5 {
    background-color: #008000;
  }

  &.status-6 {
    background-color: #d3d3d3;
  }

  &.status-7 {
    background-color: #0054a6;
  }

  &.status-8 {
    background-color: #664397;
  }

  &.status-9 {
    background-color: #00820f;
  }

  &.status-0 {
    background-color: #d3d3d3;
  }

  &.status-undefined {
    background-color: #868686;
  }
}

.status-border {
  &.status-1 {
    border-color: #b22222 !important;
  }

  &.status-2 {
    border-color: #d2ae00 !important;
  }

  &.status-3 {
    border-color: #008000 !important;
  }

  &.status-4 {
    border-color: #008000 !important;
  }

  &.status-5 {
    border-color: #008000 !important;
  }

  &.status-6 {
    border-color: #d3d3d3 !important;
  }

  &.status-7 {
    border-color: #0054a6 !important;
  }

  &.status-8 {
    border-color: #664397 !important;
  }

  &.status-9 {
    border-color: #00820f !important;
  }

  &.status-0 {
    border-color: #d3d3d3 !important;
  }

  &.status-undefined {
    border-color: #868686 !important;
  }
}

.onix-open-link {
  color: $primaryColor;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
