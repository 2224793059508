@tailwind base;
@tailwind components;
@tailwind utilities;

@import './preflight.css';

@import '../fonts/app-fonts.css';

@layer base {
  html {
    @apply text-grey190;
  }
  body {
    @apply font-segoeui;
  }
  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }

  h4 {
    @apply text-2xl;
  }

  button,
  .button,
  input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    @apply bg-none;
  }

  /* CUSTOM SCROLL */
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

@layer components {
  .oex-btn {
    @apply no-underline p-1 min-w-[5rem];
  }

  .oex-btn-primary {
    @apply bg-primarybg text-primarytext;
  }

  .oex-btn-secondary {
    @apply bg-secondarybg text-secondarytext;
  }

  .oex-btn-icon {
    @apply h-[38px] px-6 rounded-sm flex items-center gap-2 bg-primarybg text-primarytext shadow-depth4 font-medium;
  }

  .oex-input {
    @apply w-full;
  }

  .oex-btn-icon-close-modal {
    @apply bg-primarybg text-primarytext hover:bg-primarybg hover:text-primarytext;
  }
}

@layer utilities {
  .util-flex-center {
    @apply flex justify-center items-center;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}
