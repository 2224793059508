@use '../../../../assets/scss/index.scss' as *;

.job-interval .d-flex {
    display: flex;
}

.job-interval .icon {
    margin-left: 8px;
    margin-top: 1px;
    font-size: 16px;
    width: 18px;
    height: 18px;
}

.job-interval .collapse-body{
    padding: 8px 8px 0px 8px;
}