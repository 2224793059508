@use '../../../../assets/scss/index.scss' as *;

.created-modified-section{
    padding: 8px 8px 0px 8px;
}

.created-modified-region .collapse-body{
    padding-top: 0;
}

.created-modified-region{
    margin-bottom: 30px;
}