@use '../../../../assets/scss/index.scss' as *;

.equipment-detail-section {
  @include column-flex;
  gap: 15px;

  .equipment-details-tag-info {
    display: flex;
    align-items: center;
    gap: 10px;

    > .tag-info {
      display: flex;
      gap: 5px;
      align-items: center;

      > i {
        font-size: 16px;
      }
    }
  }
  .hide-or-show-fields{
    color: $primaryColor;
  }
}
