.viewer-download {
  color: #fff;
  font-family: 'onix-icon';
  font-size: 0.75rem;
  line-height: 30px;
  text-align: center;
}

.viewer-download::before {
  content: '\e900';
}
