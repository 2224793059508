@use '../../../../assets/scss/index.scss' as *;

.equipment-info {
  // @include row-flex;
  // gap: 10px;
  position: relative;

  .others-panel {
    position: absolute;
  }

  .equipment-picture {
    min-width: 153px;
    min-height: 153px;
    width: 153px;
    height: 153px;

    border-radius: 20px;
    overflow: hidden;
    @include row-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey30;
    background-color: $textColor;
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    transition: 0.25s;

    &:hover {
      box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;

      &.no-image {
        width: 100%;
        height: auto;
      }
    }
  }

  .information {
    width: 100%;
    @include column-flex;
    gap: 11px;
    overflow: hidden;

    .owner-id {
      font-weight: 600;
      font-size: 14px;
      color: $grey160;

      &.has-owner-equipment {
        padding-left: 10px;
      }
    }

    .sort-info {
      @include column-flex;
      gap: 5px;
      font-weight: 400;
      font-size: 12px;
      color: $grey160;
      padding-left: 10px;

      > * {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
  }

  .bottom-actions {
    @include row-flex;
    gap: 10px;
  }

  .product-no-wrapper {
    display: flex;
    align-items: center;

    .product-no {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: calc(100% - 200px);
      display: inline-block;
    }

    * {
      display: flex;
      margin: 0;
    }

    .company {
      // color: $grey130;
      .company-name {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }
    }
  }
}
